export const techObjOne = {
    target: "_blank",

    title1: 'Attochron Technical White Papers',
    header1: "May 2024 - Free-space Optical Communications with Broadband Optical Sources",
    button1: " ...Read More",
    link1: require('../../docs/2024 Attochron - FSOC with Broadband Optical Sources rev070924.pdf'),

    header3: "October 2023 - SPIE Paper - Atmospheric propagation of femtosecond optical pulses: Gaussian beamlet model of coherence effects",
    button3: " ...Read More",
    link3: 'https://www.spiedigitallibrary.org/conference-proceedings-of-spie/12691/126910N/Atmospheric-propagation-of-femtosecond-optical-pulses--Gaussian-beamlet-model/10.1117/12.2676888.short#_=_',

    header4: "September 2024 - Fundamental advantages of Ultrashort Optical Pulses for Free-Space Optical Communications",
    button4: " ...Read More",
    link4: require('../../docs/2024 Attochron Advantages of Impulsive Coding in FSOC-9-24-2024.pdf'),

    title2: 'Attochron Data',
    header2: "Attochron Ultrashort Pulse Laser (USPL) FSOC Data",
    subheader2: "NOTE - Bit Error Rate (BER) Data: Attochron's FSOC research and development has never used signal correction technologies like forward error correction (FEC) or channel coding. These techniques are used for controlling errors in data transmission over unreliable or noisy communication channels. Attochron's FSOC link's data rates and their bit error rate data reflect the actual performance of the FSOC links.",
    button2: " ...View USPL Data",
    link2: 'https://ln5.sync.com/dl/de84ef6b0/jbzb3s72-n8zmcnc6-c5vdr9ek-kngg4kpi',

}