import React from 'react';
import { TechContainer, TitleWrapper, Title, SectionWrapper, SubsectionWrapper, Header, Subheader, ButtonS1, ButtonS2} from './TechElements';

const TechSection = ({
    target,
    title1, header1, button1, link1,
    title2, header2, subheader2, button2, link2,
    header3, button3, link3,
    header4, button4, link4,

}) => {

    return (
        <>
            <TechContainer>
                <TitleWrapper>
                    <Title>
                        {title1}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SubsectionWrapper>
                        <Header>{header4}<ButtonS1 to={link4} target={target}>{button4}</ButtonS1></Header>
                    </SubsectionWrapper>
                    <SubsectionWrapper>
                        <Header>{header1}<ButtonS1 to={link1} target={target}>{button1}</ButtonS1></Header>
                    </SubsectionWrapper>
                    <SubsectionWrapper>
                        <Header>{header3}<ButtonS1 to={link3} target={target}>{button3}</ButtonS1></Header>
                    </SubsectionWrapper>
                </SectionWrapper>
                <TitleWrapper>
                    <Title>
                        {title2}
                    </Title>
                </TitleWrapper>
                <SectionWrapper>
                    <SubsectionWrapper>
                        <Header>{header2}<ButtonS1 to={link2} target={target}>{button2}</ButtonS1></Header>
                        <Subheader>{subheader2}</Subheader>
                    </SubsectionWrapper>
                </SectionWrapper>
            </TechContainer>
        </>
    )
}

export default TechSection;