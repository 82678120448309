import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const TechContainer = styled.div`
    background: #d3d3d3;
    position: relative;
    height: 90vh;
    overflow: hidden;
    padding-top: 100px;

    @media screen and (max-height: 1060px) {
        height: 130vh;
    }

    @media screen and (max-height: 820px) {
        height: 200vh;
    }

    @media screen and (max-height: 530px) {
        height: 270vh;
    }

    @media screen and (max-height: 395px) {
        height: 400vh;
    }

    @media screen and (max-width: 480px) {
        height: 180vh; /*270vh*/
        padding-bottom: 8rem;
    }
`

export const TitleWrapper = styled.div`
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid black;

    @media screen and (max-width: 480px) {
		max-width: 270px;
	}

    @media screen and (max-width: 370px) {
        max-width: 200px;
        margin-top: 2rem;
    }
`

export const Title = styled.h1`
    font-size: 2.3rem;
    font-weight: bold;

    @media screen and (max-width: 480px) {
		font-size: 1.8rem;
		text-align: center;
	}

    @media screen and (max-width: 370px) {
        font-size: 1.5rem;
    }
`

export const SectionWrapper = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 24px;
    max-width: 1400px;
    align-items: center;
`

export const SubsectionWrapper = styled.div`
    display: block;
    padding-bottom: 2.5rem;
`

export const Header = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    padding-bottom: .5rem;

    @media screen and (max-width: 480px) {
		font-size: 1.3rem;
        padding-bottom: 1rem;
	}

    @media screen and (max-width: 370px) {
        font-size: 1rem;
    }
`

export const Subheader = styled.h2`
    font-size: 22px;
    color: #000000;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-left: 3rem;

    @media screen and (max-width: 480px) {
		font-size: 1.2rem;
        padding-bottom: 1rem;
	}

    @media screen and (max-width: 370px) {
        font-size: 0.9rem;
    }
`

export const ButtonS1 = styled(LinkR)`
    font-size: 18px;
    color: #2f8eed;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #000000;
        transition: all 0.3s ease-in-out;
    }

    @media screen and (max-width: 480px) {
		font-size: 1rem;
	}

    @media screen and (max-width: 370px) {
        font-size: .8rem;
    }
`

export const ButtonS2 = styled(LinkR)`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    background: transparent;
    text-decoration: none;
    color: #000000;
    border: 4px solid #000000;
    border-radius: 18px;
    padding: 8px 24px 8px 24px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
		font-size: 24px;
	}

	@media screen and (max-width: 480px) {
		font-size: 16px;
        padding: 6px 12px 6px 12px;
	}

    @media screen and (max-width: 370px) {
        font-size: 12px;
        padding: 3px 9px 3px 9px;
    }
`